<template>
  <menu_div></menu_div>
  <div id="div_mask"></div>
  <div id="div_reg">
    <h3>填写注册信息</h3>
    <div>
      <ul>
        <li>
          <label for="inputEmail"
            >邮箱：
            <span v-if="emailFormatError" style="color: red">{{
              emailFormatError
            }}</span>
            <span v-else style="color: green">{{ emailFormat }}</span>
          </label>
          <input
            v-model="email1"
            @input="checkEmailFormat"
            type="text"
            autocomplete="off"
            placeholder="请输入您的邮箱"
            id="inputEmail"
          />
        </li>
        <li>
          <label for="inputEmail2"
            >确认邮箱：
            <span v-if="emailFormatError" style="color: red">{{
              emailFormatError
            }}</span>
            <span v-else style="color: green">{{ emailFormat }}</span>
          </label>
          <input
            v-model="email2"
            @input="checkEmailFormat"
            type="text"
            id="inputEmail2"
            autocomplete="off"
            placeholder="请再次输入您的邮箱"
          />
        </li>
        <li>
          <small
            >请牢记并保管好您的邮箱，如果您忘记了密码，可以通过邮箱来重置密码。<br />
            <span v-if="emaiMatch" style="color: red">{{ emaiMatch }}</span>
          </small>
        </li>
      </ul>
    </div>
    <div class="reg_button">
      <supp_button @click="checkEmailMatch" :class="{ disabled: isDisabled }"
        >获取验证码</supp_button
      >
    </div>
    <div>
      <ul>
        <li>
          <label for="code"
            >验证码:
            <span v-if="codeFormat" style="color: red">{{ codeFormat }}</span>
          </label>
          <input
            v-model="code"
            @input="checkCode"
            type="text"
            id="code"
            autocomplete="off"
            placeholder="请输入您邮箱收到的验证码"
          />
        </li>
        <li>
          <label for="inputUser"
            >账号：
            <span v-if="inputUserFormat" style="color: red">{{
              inputUserFormat
            }}</span>
          </label>
          <input
            v-model="inputUser"
            @input="checkUser"
            type="text"
            id="inputUser"
            autocomplete="off"
            placeholder="请输入您的账号（英文字母和数字，不能以数字开头）"
          />
        </li>
        <li>
          <label for="inputPass"
            >密码：
            <span v-if="inputPassFormat" style="color: red">{{
              inputPassFormat
            }}</span>
          </label>
          <input
            v-model="inputPass"
            @input="checkPass"
            type="password"
            id="inputPass"
            autocomplete="off"
            placeholder="请输入您的密码"
          />
        </li>
        <li>
          <label for="inputConfirm"
            >确认密码：
            <span v-if="inputPassFormat" style="color: red">{{
              inputPassFormat
            }}</span>
          </label>
          <input
            v-model="inputConfirm"
            @input="checkPass"
            type="password"
            id="inputConfirm"
            autocomplete="off"
            placeholder="请再次输入您的密码"
          />
        </li>
        <li>
          <span v-if="passMatch" style="color: red">{{ passMatch }}</span>
        </li>
      </ul>
    </div>
    <div class="reg_button">
      <supp_button @click="userRegister" :class="{ disabled: regDisabled }"
        >注册</supp_button
      >
    </div>
  </div>
</template>

<script>
import menu_div from "@/components/common/menu_div";
import supp_button from "@/components/common/supp_button";
import { get_code, userSignup } from "@/network/reg_getcode";
import { get_err_msg } from "@/network/errMsg";

export default {
  name: "Register",
  components: {
    menu_div,
    supp_button,
  },
  data() {
    return {
      //邮箱获取验证码
      agent: "",
      emailFormatError: "",
      emailFormat: "",
      email1: "",
      email2: "",
      emaiMatch: "",
      //注册用户
      code: "",
      inputUser: "",
      inputPass: "",
      inputConfirm: "",
      passMatch: "",
      //验证码，用户，密码格式检测
      codeFormat: "",
      inputUserFormat: "",
      inputPassFormat: "",
      //点击变为灰色不可用
      isDisabled: false,
      regDisabled: false,
    };
  },
  created() {
    // 获取查询参数并存储在组件状态中
    this.agent = this.$route.meta.agent || "";
  },
  watch: {
    $route(to, from) {
      this.setAgent()
    },
  },
  methods: {
    setAgent() {
      this.agent = this.$route.query.agent || ""
    },
    //验证验证码
    checkCode(event) {
      const code = event.target.value.trim();
      const codeRegex = /^[A-Za-z0-9]{1,6}$/;
      if (!codeRegex.test(code)) {
        this.codeFormat = "验证码不能超过6位";
      } else {
        this.codeFormat = "";
      }
    },
    //验证用户名
    checkUser(event) {
      const user = event.target.value.trim();
      const userRegex = /^[A-Za-z][A-Za-z0-9]{4,11}$/;
      if (!userRegex.test(user)) {
        this.inputUserFormat =
          "请使用用英文字母和数字，不能以数字开头，5到12位长度";
      } else {
        this.inputUserFormat = "";
      }
    },
    //验证密码
    checkPass(event) {
      const passwd = event.target.value.trim();
      const passwdRegex = /^[A-Za-z0-9]{6,12}$/;
      if (!passwdRegex.test(passwd)) {
        this.inputPassFormat = "请使用用英文字母和数字，6~12位长度";
      } else {
        this.inputPassFormat = "";
      }
    },
    //验证邮箱
    checkEmailFormat(event) {
      const input_email = event.target.value.trim();
      // const emailRegex = /^\w+([-+.]\w+)*@(qq|gmail\.com)/
      const emailRegex = /^\w[-\w.+]*@(qq|gmail)\.com$/;
      if (!emailRegex.test(input_email)) {
        this.emailFormat = "";
        this.emailFormatError = "邮箱格式错误";
      } else {
        this.emailFormatError = "";
        this.emailFormat = "邮箱格式正确";
      }
    },
    checkEmailMatch() {
      this.isDisabled = true;
      setTimeout(() => {
        this.disabled = false;
      }, 60000);
      if (this.email1 === "" || this.email2 === "") {
        this.emaiMatch = "邮箱不能为空";
      } else if (this.email1 === this.email2) {
        get_code(this.email1).then(
          (res) => {
            console.log(res);
            this.emaiMatch = "邮件已发送，请到邮箱查收邮件";
          },
          (err) => {
            this.emaiMatch = "err.message";
            console.log(err);
          }
        );
      } else {
        this.emaiMatch = "两次输入的邮箱不相同";
      }
    },
    //注册用户
    userRegister() {
      this.regDisabled = true
      if (
        this.code === "" ||
        this.inputUser === "" ||
        this.inputPass === "" ||
        this.inputConfirm === "" ||
        this.email1 === ""
      ) {
        this.passMatch = "输入不能为空";
      } else if (this.inputPass === this.inputConfirm) {
        userSignup(this.code, this.email1, this.inputUser, this.inputPass, this.agent).then(
          (res) => {
            if ("name" in res) {
              this.passMatch = "注册成功";
              window.sessionStorage.setItem("user", res.name);
              window.sessionStorage.setItem("email", res.email);
              window.sessionStorage.setItem("nx", res.nx_credit);
              window.sessionStorage.setItem("cash", res.nx_cash);
              setTimeout(() => {
                this.$router.push("/download");
              }, 3000);
            } else {
              this.passMatch = get_err_msg(res.data.errCode);
            }
          }
        );
      } else {
        this.passMatch = "两次输入的密码不相同";
      }
    },
  },
};
</script>

<style>
#div_mask {
  position: fixed;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

#div_reg {
  position: absolute;
  width: 60%;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url("@/assets/img/base/mb.png");
  background-size: cover;
  border: 1px solid #cfcfcf;
  padding: 10px;
}

#div_reg ul {
  margin-top: 10px;
  text-align: center;
}

#div_reg li {
  list-style: none;
}

#div_reg h3 {
  margin: 10px 0 0 0;
  font-weight: 600;
  color: #000000;
}

#div_reg ul li label {
  width: 100%;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  margin-top: 8px;
  text-align: left;
}

#div_reg ul li input {
  width: 100%;
  height: 42px;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  font-size: 14px;
  /*font-family: PingFang SC;*/
  font-weight: 400;
  color: #999999;
  /*margin-top: 10px;*/
  /*margin-bottom: 20px;*/
}

.disabled {
  background-color: gray;
  /*color:gray;*/
  cursor: not-allowed;
  pointer-events: none;
}
.reg_button {
  text-align: center;
}
@media (max-width: 768px) {
  #div_reg {
    width: 80%;
    top: 60%;
  }
  .reg_button li {
    width: 80%;
  }
}
</style>