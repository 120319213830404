import { request } from "@/network/request";
import qs from 'qs'

export function forget_code(user_name, user_email) {
  // console.log(user_name,user_email);
  return request({
    method: 'post',
    url: '/user/resetPwd/code',
    data: qs.stringify({
      name: user_name,
      email: user_email
    }),
  })
}
export function forget_change(user_name, user_code, user_passwd, user_passwd_confirm) {
  // console.log(user_name,user_code,user_passwd,user_passwd_confirm);
  return request({
    method: 'post',
    url: '/user/resetPwd',
    data: qs.stringify({
      name: user_name,
      code: user_code,
      password: user_passwd,
      password_confirm: user_passwd_confirm
    }),
  })
}
export function reset_pic(user_name, user_code, user_passwd, user_passwd_confirm) {
  // console.log(user_name,user_code,user_passwd,user_passwd_confirm);
  return request({
    method: 'post',
    url: '/user/resetPic',
    data: qs.stringify({
      name: user_name,
      code: user_code,
      pic: user_passwd,
      pic_confirm: user_passwd_confirm
    }),
  })
}
