import { request } from "@/network/request";
// import qs from 'qs'

export function get_world_level() {
  return request({
    method: 'get',
    url: 'https://ssl.mp1314.top/worldLevel',
  })
}

export function get_game_data(index, count, rank_type) {
  return request({
    method: 'get',
    url: 'player/ranks?index=' + index + '&count=' + count + '&type=' + rank_type,
  })
}
export function get_char_data(charName) {
  return request({
    method: 'get',
    url: '/player/ranks/index?name=' + charName + '&type=6'
  })
}