import { request } from "@/network/request";
import qs from 'qs'

export function get_code(inputEmail) {
  return request({
    method: 'post',
    url: '/user/register/code',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      email: inputEmail
    })
  })
}

export function userSignup(code, email, inputUser, inputPass, agent) {
  return request({
    method: 'post',
    url: '/user/signup',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      email: email,
      code: code,
      name: inputUser,
      password: inputPass,
      agent: agent
    })
  })
}