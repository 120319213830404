import { request } from "@/network/request";
import qs from 'qs'

export function get_change_code(user_email) {
  // console.log(user_email);
  return request({
    method: 'post',
    url: '/user/email/code',
    // headers: {
    //     contentType: 'application/x-www-form-urlencoded',
    // },
    data: qs.stringify({
      email: user_email
    }),
  })
}
export function update_email(inputEmail, code, new_code) {
  // console.log(inputEmail,code,new_code);
  return request({
    method: 'post',
    url: '/user/email/update',
    // headers: {
    //     contentType: 'application/x-www-form-urlencoded',
    // },
    data: qs.stringify({
      email: inputEmail,
      code: code,
      target_code: new_code,
    }),
  })
}