<template>
  <div id="down_div">
    <h2>WingsMS下载</h2>
    <p>
      我们提供了两种下载渠道，普通下载和Google网盘。玩家可以根据自己的网络环境来选择。每一次大版本更新后，玩家需要从官网下载最新客户端。
    </p>
  </div>
  <div id="down_cont">
    <div class="down_ul_img">
      <img src="../../assets/img/download/download.jpg" alt="" />
    </div>
    <div class="down_ul">
      <p>
        解压客户端前，建议关闭所有杀毒软件，并在“Windows安全中心”添加信任文件夹/文件。否则可能造成文件损坏，极大影响游戏的稳定性。请提前查阅KOOK社区中的#常见问题频道，以解决客户端遇到的相关问题。
      </p>
      <ul>
        <supp_button v-for="item in this.date" :button_link="item.url">{{
          item.name
        }}</supp_button>
      </ul>
    </div>
  </div>
  <!--  <div style="clear:both"></div>-->
</template>

<script>
import menu_div from "@/components/common/menu_div";
import supp_button from "@/components/common/supp_button";
import { get_down_url } from "@/network/download";
export default {
  name: "Download",
  components: {
    menu_div,
    supp_button,
  },
  data() {
    return {
      date: [],
    };
  },
  methods: {
    get_url() {
      get_down_url().then((res) => {
        // console.log(res);
        this.date = res;
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.get_url();
    }, 100);
  },
};
</script>

<style scoped>
#down_div {
  padding-top: 40px;
}
#down_div h2 {
  /*font-size: 32px;*/
  /*font-family: FZLanTingHeiS-H-GB-Regular, FZLanTingHeiS-H-GB;*/
  font-weight: 600;
  color: #000000;
  line-height: 65px;
}
#down_div p {
  font-size: 14px;
  /*font-family: PingFang SC-Regular, PingFang SC;*/
  font-weight: 400;
  color: #999999;
  line-height: 40px;
}
#down_cont {
  display: flex;
}

.down_ul {
  margin-top: 40px;
  flex: 1;
}
.down_ul ul {
  text-align: center;
}
.down_ul_img {
  margin-top: 40px;
  flex: 1;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.down_ul p {
  font-size: 14px;
  /*font-family: PingFang SC-Regular, PingFang SC;*/
  font-weight: 400;
  color: #999999;
  line-height: 40px;
}
.down_ul li {
  width: 42%;
}
@media (max-width: 768px) {
  #down_cont {
    display: flex;
    flex-direction: column;
  }
  .down_ul_img img {
    max-width: 80%;
  }
  .down_ul li {
    width: 48%;
  }
  #down_div {
    padding: 0;
  }
}
</style>