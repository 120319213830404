<template>
  <comm_vote></comm_vote>
  <div class="sup">
    <div>
      <span style="color: red">{{ sup_val }}</span>
    </div>
    <supp_button @click="get_support">赞助</supp_button>
  </div>
</template>

<script>
import comm_vote from "@/components/common/comm_vote";
import supp_button from "@/components/common/supp_button";
import { user_support } from "@/network/support";
export default {
  name: "Support",
  components: {
    comm_vote,
    supp_button,
  },
  data() {
    return {
      sup_val: "",
    };
  },
  methods: {
    get_support() {
      const token = window.sessionStorage.getItem("Authorization");
      if (token) {
        user_support().then((res) => {
          // console.log(res);
          window.open("https://" + res, "_black");
        });
      } else {
        this.sup_val = "帐号未登录";
      }
    },
  },
};
</script>

<style scoped>
.sup {
  margin-top: 60px;
  text-align: center;
}
@media (max-width: 768px) {
  .sup {
    margin-top: 120px;
  }
  .sup li {
    width: 60%;
  }
}
</style>