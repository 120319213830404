import { createStore } from 'vuex'

// export default createStore({
export const store = createStore({
  state: {
    user: null,
    email: null,
    nx: null,
    cash: null,
    token: null,
    SerialNumber: null,
    vote: null
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setEmail(state, email) {
      state.email = email
    },
    setNx(state, nx) {
      state.nx = nx
    },
    setCash(state, cash) {
      state.cash = cash
    },
    setToken(state, token) {
      state.token = token
    },
    setSerialNumber(state, SerialNumber) {
      state.SerialNumber = SerialNumber
    },
    setVote(state, vote) {
      state.vote = vote
    },
    clearUser(state) {
      state.user = null
      state.email = null
      state.nx = null
      state.cash = null
      state.token = null
      state.SerialNumber = null
      state.vote = null
    }
  },
  actions: {
    login({ commit }, { user, token, SerialNumber, email, nx, cash, vote }) {
      commit('setUser', user)
      commit('setToken', token)
      commit('setSerialNumber', SerialNumber)
      commit('setEmail', email)
      commit('setNx', nx)
      commit('setCash', cash)
      commit('setVote', vote)
    },
    logout({ commit }) {
      commit('clearUser')
    }
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token
    }
  }
})