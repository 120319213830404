import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Home from '@/views/home/Home.vue'
import Download from '@/views/download/Download.vue'
import Register from '@/views/register/Register.vue'
import Rank from '@/views/rank/Rank.vue'
import Support from '@/views/support/Support.vue'
import Vote from '@/views/vote/Vote.vue'
import Forgetpass from '@/views/forgetpass/Forgetpass.vue'
import Changepic from '@/views/changepic/Changepic.vue'
import ChangeEmail from '@/views/changeemail/ChangeEmail.vue'
import BindMail from '@/views/bindmail/BindMail.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/download',
    name: 'download',
    component: Download
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: (to, from, next) => {
      const agent = to.query.agent
      to.meta.agent = agent
      next()
    }
  },
  {
    path: '/rank',
    name: 'rank',
    component: Rank
  },
  {
    path: '/support',
    name: 'support',
    component: Support
  },
  {
    path: '/vote',
    name: 'vote',
    component: Vote
  },
  {
    path: '/forgetpass',
    name: 'forgetpass',
    component: Forgetpass
  },
  {
    path: '/changepic',
    name: 'changepic',
    component: Changepic
  },
  {
    path: '/changeemail',
    name: 'changeemail',
    component: ChangeEmail
  },
  {
    path: '/bindemail',
    name: 'bindemail',
    component: BindMail
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
