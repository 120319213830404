<template>
  <menu_div></menu_div>
  <div id="div_mask"></div>
  <div id="div_reg">
    <h3>修改<strong style="color: red">游戏内</strong>二级密码</h3>
    <div>
      <ul>
        <li>
          <label for="inputUser"
            >账号：
            <span v-if="inputUserFormat" style="color: red">{{
              inputUserFormat
            }}</span>
          </label>
          <input
            v-model="inputUser"
            type="text"
            id="inputUser"
            autocomplete="off"
            placeholder="请输入您的游戏账号"
          />
        </li>
        <li>
          <label for="inputEmail"
            >邮箱：
            <span v-if="emailFormatError" style="color: red">{{
              emailFormatError
            }}</span>
            <span v-else style="color: green">{{ emailFormat }}</span>
          </label>
          <input
            v-model="email1"
            type="text"
            autocomplete="off"
            placeholder="请输入您的邮箱"
            id="inputEmail"
          />
        </li>

        <li>
          <small
            >请牢记并保管好您的邮箱，如果您忘记了密码，可以通过邮箱来重置密码。<br />
            <span v-if="emaiMatch" style="color: red">{{ emaiMatch }}</span>
          </small>
        </li>
      </ul>
    </div>
    <div class="reg_button">
      <supp_button @click="checkEmailMatch" :class="{ disabled: isDisabled }"
        >获取验证码</supp_button
      >
    </div>
    <div>
      <ul>
        <li>
          <label for="code"
            >验证码:
            <span v-if="codeFormat" style="color: red">{{ codeFormat }}</span>
          </label>
          <input
            v-model="code"
            @input="checkCode"
            type="text"
            id="code"
            autocomplete="off"
            placeholder="请输入您邮箱收到的验证码"
          />
        </li>
        <li>
          <label for="inputPass"
            >密码：
            <span v-if="inputPassFormat" style="color: red">{{
              inputPassFormat
            }}</span>
          </label>
          <input
            v-model="inputPass"
            @input="checkPass"
            type="password"
            id="inputPass"
            autocomplete="off"
            placeholder="请输入您的密码"
          />
        </li>
        <li>
          <label for="inputConfirm"
            >确认密码：
            <span v-if="inputPassFormat" style="color: red">{{
              inputPassFormat
            }}</span>
          </label>
          <input
            v-model="inputConfirm"
            @input="checkPass"
            type="password"
            id="inputConfirm"
            autocomplete="off"
            placeholder="请再次输入您的密码"
          />
        </li>
        <li>
          <span v-if="passMatch" style="color: red">{{ passMatch }}</span>
        </li>
      </ul>
    </div>
    <div class="reg_button">
      <supp_button @click="change_pic" :class="{ disabled: regDisabled }"
        >重置二级密码</supp_button
      >
    </div>
  </div>
</template>

<script>
import menu_div from "@/components/common/menu_div";
import supp_button from "@/components/common/supp_button";
import { forget_code, reset_pic } from "@/network/forgetpass";
import { get_err_msg } from "@/network/errMsg";

export default {
  name: "Changepic",
  components: {
    menu_div,
    supp_button,
  },
  data() {
    return {
      //邮箱获取验证码
      emailFormatError: "",
      emailFormat: "",
      email1: "",
      email2: "",
      emaiMatch: "",
      //注册用户
      code: "",
      inputUser: "",
      inputPass: "",
      inputConfirm: "",
      passMatch: "",
      //验证码，用户，密码格式检测
      codeFormat: "",
      inputUserFormat: "",
      inputPassFormat: "",
      //点击变为灰色不可用
      isDisabled: false,
      regDisabled: false,
    };
  },
  methods: {
    //验证验证码,用户名,密码格式
    checkCode(event) {
      const code = event.target.value.trim();
      const codeRegex = /^[A-Za-z0-9]{1,6}$/;
      if (!codeRegex.test(code)) {
        this.codeFormat = "验证码不能超过6位";
      } else {
        this.codeFormat = "";
      }
    },
    checkUser(event) {
      const user = event.target.value.trim();
      const userRegex = /^[A-Za-z][A-Za-z0-9]{4,11}$/;
      if (!userRegex.test(user)) {
        this.inputUserFormat =
          "请使用用英文字母和数字，不能以数字开头，5到12位长度";
      } else {
        this.inputUserFormat = "";
      }
    },
    checkPass(event) {
      const passwd = event.target.value.trim();
      const passwdRegex = /^[A-Za-z0-9]{6,12}$/;
      if (!passwdRegex.test(passwd)) {
        this.inputPassFormat = "请使用用英文字母和数字，6~12位长度";
      } else {
        this.inputPassFormat = "";
      }
    },
    //验证邮箱
    checkEmailFormat(event) {
      const input_email = event.target.value.trim();
      // const emailRegex = /^\w+([-+.]\w+)*@(qq|gmail\.com)/
      const emailRegex = /^\w[-\w.+]*@(qq|gmail)\.com$/;
      if (!emailRegex.test(input_email)) {
        this.emailFormat = "";
        this.emailFormatError = "邮箱格式错误";
      } else {
        this.emailFormatError = "";
        this.emailFormat = "邮箱格式正确";
      }
    },
    checkEmailMatch() {
      this.isDisabled = true;
      setTimeout(() => {
        this.disabled = false;
      }, 60000);
      if (this.email1 === "") {
        this.emaiMatch = "邮箱不能为空";
      } else {
        forget_code(this.inputUser, this.email1).then((res) => {
          // console.log(res);
          this.emaiMatch = "邮件已发送，请到邮箱查收邮件";
        });
      }
    },
    //重置密码
    change_pic() {
      this.regDisabled = true;
      if (
        this.code === "" ||
        this.inputUser === "" ||
        this.inputPass === "" ||
        this.inputConfirm === ""
      ) {
        this.passMatch = "输入不能为空";
      } else if (this.inputPass === this.inputConfirm) {
        reset_pic(
          this.inputUser,
          this.code,
          this.inputPass,
          this.inputConfirm
        ).then((res) => {
          if ("success" in res) {
            this.passMatch = "重置二级密码成功";
            setTimeout(() => {
              this.$router.push("/");
            }, 3000);
          } else {
            this.passMatch = get_err_msg(res.data.errCode);
          }
        });
      } else {
        this.passMatch = "两次输入的密码不相同";
      }
    },
  },
};
</script>

