<template>
  <menu_div></menu_div>
  <div id="div_mask"></div>
  <div id="div_reg">
    <h3>换绑邮箱（请您先登录换绑的帐号）</h3>
    <div>
      <ul>
        <li>
          <label for="inputEmail">邮箱： </label>
          <input
            v-model="email1"
            type="text"
            autocomplete="off"
            placeholder="请输入您的邮箱"
            id="inputEmail"
          />
        </li>
        <li>
          <small>
            <span v-if="emaiMatch" style="color: red">{{ emaiMatch }}</span>
          </small>
        </li>
      </ul>
    </div>
    <div class="reg_button">
      <supp_button
        @click="checkEmailMatch(this.email1, isDisabled)"
        :class="{ disabled: isDisabled }"
        >获取验证码
      </supp_button>
    </div>
    <div>
      <ul>
        <li>
          <label for="first_code"
            >验证码:
            <span v-if="codeFormat" style="color: red">{{ codeFormat }}</span>
          </label>
          <input
            v-model="code"
            @input="checkCode"
            type="text"
            id="first_code"
            autocomplete="off"
            placeholder="请输入您邮箱收到的验证码"
          />
        </li>
        <li>
          <label for="inputEmail2"
            >确认邮箱：
            <span v-if="emailFormatError" style="color: red">{{
              emailFormatError
            }}</span>
            <span v-else style="color: green">{{ emailFormat }}</span>
          </label>
          <input
            v-model="email2"
            @input="checkEmailFormat"
            type="text"
            id="inputEmail2"
            autocomplete="off"
            placeholder="请输入您的新邮箱"
          />
        </li>
        <li>
          <small>
            <span v-if="emaiMatch" style="color: red">{{ emaiMatch }}</span>
          </small>
        </li>
      </ul>
    </div>
    <div class="reg_button">
      <supp_button
        @click="checkEmailMatch(this.email2, this.isDisabled2)"
        :class="{ disabled: isDisabled2 }"
        >获取验证码
      </supp_button>
    </div>
    <div>
      <ul>
        <li>
          <label for="new_code"
            >验证码:
            <span v-if="codeFormat" style="color: red">{{ codeFormat }}</span>
          </label>
          <input
            v-model="new_code"
            @input="checkCode"
            type="text"
            id="new_code"
            autocomplete="off"
            placeholder="请输入您邮箱收到的验证码"
          />
        </li>
        <li>
          <span v-if="passMatch" style="color: red">{{ passMatch }}</span>
        </li>
      </ul>
    </div>
    <div class="reg_button">
      <supp_button @click="change_email" :class="{ disabled: regDisabled }"
        >换绑邮箱</supp_button
      >
    </div>
  </div>
</template>

<script>
import menu_div from "@/components/common/menu_div";
import supp_button from "@/components/common/supp_button";
import { get_change_code, update_email } from "@/network/changeMail";
import { get_err_msg } from "@/network/errMsg";
import { user_logout } from "@/network/login";

export default {
  name: "ChangeEmail",
  components: {
    menu_div,
    supp_button,
  },
  data() {
    return {
      //邮箱获取验证码
      emailFormatError: "",
      emailFormat: "",
      email1: "",
      email2: "",
      emaiMatch: "",
      //注册用户
      code: "",
      inputUser: "",
      inputPass: "",
      inputConfirm: "",
      passMatch: "",
      new_code: "",
      //验证码，用户，密码格式检测
      codeFormat: "",
      inputUserFormat: "",
      inputPassFormat: "",
      //点击变为灰色不可用
      isDisabled: false,
      isDisabled2: false,
      regDisabled: false,
    };
  },
  methods: {
    //验证验证码,用户名,密码格式
    checkCode(event) {
      const code = event.target.value.trim();
      const codeRegex = /^[A-Za-z0-9]{1,6}$/;
      if (!codeRegex.test(code)) {
        this.codeFormat = "验证码不能超过6位";
      } else {
        this.codeFormat = "";
      }
    },
    checkUser(event) {
      const user = event.target.value.trim();
      const userRegex = /^[A-Za-z][A-Za-z0-9]{4,11}$/;
      if (!userRegex.test(user)) {
        this.inputUserFormat =
          "请使用用英文字母和数字，不能以数字开头，5到12位长度";
      } else {
        this.inputUserFormat = "";
      }
    },
    checkPass(event) {
      const passwd = event.target.value.trim();
      const passwdRegex = /^[A-Za-z0-9]{6,12}$/;
      if (!passwdRegex.test(passwd)) {
        this.inputPassFormat = "请使用用英文字母和数字，6~12位长度";
      } else {
        this.inputPassFormat = "";
      }
    },
    //验证邮箱
    checkEmailFormat(event) {
      const input_email = event.target.value.trim();
      // const emailRegex = /^\w+([-+.]\w+)*@(qq|gmail\.com)/
      const emailRegex = /^\w[-\w.+]*@(qq|gmail)\.com$/;
      if (!emailRegex.test(input_email)) {
        this.emailFormat = "";
        this.emailFormatError = "邮箱格式错误";
      } else {
        this.emailFormatError = "";
        this.emailFormat = "邮箱格式正确";
      }
    },
    //获取验证码
    checkEmailMatch(email, disabled) {
      disabled = true;
      setTimeout(() => {
        this.disabled = false;
      }, 60000);
      const token = window.sessionStorage.getItem("Authorization");
      if (token) {
        if (email === "") {
          this.emaiMatch = "邮箱不能为空";
        } else {
          get_change_code(email).then((res) => {
            // console.log(res);
            this.emaiMatch = "邮件已发送，请到邮箱查收邮件";
          });
        }
      } else {
        this.emaiMatch = "请您先登录需要换绑的帐号";
      }
    },
    //换绑邮箱
    change_email() {
      this.regDisabled = true;
      if (this.code === "" || this.new_code === "" || this.email2 === "") {
        this.passMatch = "输入不能为空";
      } else {
        update_email(this.email2, this.code, this.new_code).then((res) => {
          console.log(res);
          if ("success" in res) {
            this.passMatch = "换绑邮箱成功,请重新登录！";
            user_logout().then((res) => {
              window.sessionStorage.removeItem("user");
              window.sessionStorage.removeItem("email");
              window.sessionStorage.removeItem("nx");
              window.sessionStorage.removeItem("cash");
              window.sessionStorage.removeItem("Authorization");
              // this.updateIsShowLogin(false)
              // window.location.reload()
            });
            setTimeout(() => {
              this.$router.push("/");
            }, 3000);
          } else {
            this.passMatch = get_err_msg(res.data.errCode);
          }
        });
      }
    },
  },
};
</script>

