import { request } from "@/network/request";

export function user_login(user, passwd) {
  // console.log(user,passwd);
  return request({
    method: 'post',
    url: '/user/signin',
    headers: {
      // 'Serial-Number': 'SerialNumber',
      'Content-Type': 'application/json'
    },
    data: JSON.stringify({
      name: user,
      password: passwd
    }),
  })
}

export function user_logout() {
  return request({
    method: 'post',
    url: '/user/logout',
  })
}